<template>
    <div class="action-schedule">
        <template>
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span>약속이 확정되었어요!</span>
                </div>
                <div class="message-content">
                    <div class="desc" v-for="(d, idx) in desc" :key="idx" :class="{ 'li-dot': desc.length > 1 }">
                        <!-- <div :class="{'li-dot' : desc.length > 1}" v-html="''"/> -->
                        <!-- <div> -->
                        {{ d }}
                        <!-- </div> -->
                    </div>
                    <div class="grey-box m-t-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div class="grey-box m-t-8">
                        <div class="title-grey">약속 내용</div>
                        <!--   하드코딩  -->
                        <div class="content-grey">
                            <p class="m-b-4">{{ time }}</p>
                            <p>{{ content.meet_place }}에서 {{ meetType }}예정</p>
                        </div>
                    </div>
                    <div
                        class="pink-box m-t-8 p-l-16 m-b-8"
                        v-if="isMale && isSelfReservation && content.meet_type_confirmed !== 'coffee'"
                    >
                        <div class="title-pink">여성분이 꺼리는 음식</div>
                        <div class="content-pink">{{ foodPrefer }}</div>
                    </div>
                    <div v-else class="m-b-8"></div>
                    <BottomButton
                        :nonFixed="true"
                        v-if="isMale && isSelfReservation"
                        :label="'만남장소 입력하기'"
                        @click="openModalMeetingPlaceInput"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleComplete',
    props: ['message'],
    computed: {
        isMale() {
            return this.$store.getters.me.gender === 0
        },
        desc() {
            if (this.isMale && this.isSelfReservation) {
                return [
                    '장소를 직접 예약하신 후에, 장소를 입력해주세요. 장소를 입력하시면 상대에게 안내 sms가 발송됩니다.',
                    '만남 당일 오전 9시에 비상연락용 대화방이 앱 내에 생성될 예정입니다.',
                    // "변경/취소는 ‘약속 관리' 탭에서 할 수 있습니다.",
                ]
            } else {
                return [
                    '구체적인 레스토랑/카페는 매니저가 예약 완료 후 안내드릴 예정입니다. ',
                    '만남 당일 오전 9시에 비상연락용 대화방이 앱 내에 생성될 예정입니다.',
                ]
            }
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return `${this.$options.filters.asAge(this.content.birthday)}세`
        },
        isSelfReservation() {
            return this.content.self_reservation === 1
        },
        time() {
            const dateConfirmed = this.content.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        meetType() {
            const fixed = ['coffee', 'meal']

            return fixed.includes(this.content.meet_type_confirmed)
                ? this.$translate(this.content.meet_type_confirmed.toUpperCase())
                : '커피 또는 식사'
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
    },
    methods: {
        async openModalMeetingPlaceInput() {
            await this.$modal.custom({
                component: 'ModalMeetingPlaceInput',
                options: {
                    schedule_id: this.content.schedule_id,
                },
            })
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
    &.li-dot {
        display: list-item;
        align-items: flex-start;
        margin-left: 16px;
    }
}
</style>
